html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  overflow: auto !important;
}

#root, .app-container {
  background-color: #f5f5f5;
}

.app-container {
  margin-top: 230px !important;
  background-color: #f5f5f5;
}

footer {
  background-color: #f5f5f5;
}

.app-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9000;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 800px) {
  .app-navbar .d-flex {
    flex-direction: column !important;
  }
  .app-navbar .d-flex {
    margin-bottom: 5px;
  }
  .app-navbar .contact {
    width: 100%;
  }
  .app-navbar .contact button {
    width: 100%;
    flex-direction: row !important;
  }
}

/* header {
  background-image: url('./assets/header_pc_27.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
} */

.opacity-50 {
  background-color: #ffffffaa;
}

.pc27-bg-blue {
  background-color: #1d439c;
}

.pc27-cl-blue {
  color: #1d439c !important;
}

.pc27-bg-red {
  background-color: #ed1c24;
}

.pc27-cl-red {
  color: #ed1c24;
}

.MuiButton-containedSecondary {
  color: white !important;
  background-color: #ed1c24 !important;
}

.app-navbar .pc27-logo {
  height: 100px;
}

.app-navbar header .lead {
  font-size: 24px;
  font-weight: 500;
}

.Mui-selected {
  background-color: #1d439c !important;
  color: white !important;
}

.product-card {
  position: relative;
  background-color: white;
  cursor: pointer;
  height: 300px;
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  object-position: center center;
}

.product-card:hover {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.product-card:hover .title {
  text-decoration: underline;
  color: #1d439c;
}

.product-card .title {
  font-size: small;
}

.product-card .price-holder {
  font-weight: 600;
  line-height: 1;
}

.promo-theme {
  text-decoration: line-through;
  font-weight: 300 !important;
  font-size: 12px !important;
}

.promo-over {
  position: absolute;
  left: 8%;
  top: 4%;
  height: 45px;
  width: 45px;
  color: white;
  font-weight: 600;
}

.out-of-stock-over {
  position: absolute;
  right: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  color: white;
  font-weight: 600;
}

.out-of-stock-over .text-container {
  background-color: #ed1c2499;
}

.quantity-over {
  position: absolute;
  right: 8%;
  top: 4%;
  height: 45px;
  width: 45px;
  font-weight: 600;
}

.focus-card {
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.focus-card .MuiButtonBase-root {
  opacity: 0;
}

.focus-card:hover {
  background-color: #ffffffce;
}

.focus-card:hover .MuiButtonBase-root {
  opacity: 1;
}

.selected-picture img {
  height: 350px;
  object-fit: cover;
  object-position: center center;
}

.image-list img {
  height: 120px;
  width: 200px;
  object-fit: scale-down;
  object-position: center center;
}

.image-list {
  width: 100% !important;
  overflow-x: auto;
}

.image-list::-webkit-scrollbar {
  width: 5px;
}

.image-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.image-list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.horizental-card {
  min-height: 120px !important;
}

.horizental-card img {
  height: 100% !important;
  width: 200px;
  width: 200px;
  object-fit: scale-down;
  object-position: center center;
}

.product-list {
  height: 400px !important;
  overflow-y: auto;
}

.step-content {
  min-height: 470px;
}

.product-list::-webkit-scrollbar {
  width: 5px;
}

.product-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.product-list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

textarea {
  resize: none !important;
}

.csl-item {
  height: 450px !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.csl-item .caption {
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 10%;
  background-color: #ffffffce;
}

.bg-progress {
  background: linear-gradient(90deg, #1d439c, #d9d9d9);
  background-size: 400% 400%;
  -webkit-animation: ProgressAnimation 10s ease infinite;
  -moz-animation: ProgressAnimation 10s ease infinite;
  animation: ProgressAnimation 10s ease infinite;
}

@-webkit-keyframes ProgressAnimation {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 91%;
  }
  100% {
    background-position: 0% 10%;
  }
}

@-moz-keyframes ProgressAnimation {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 91%;
  }
  100% {
    background-position: 0% 10%;
  }
}

@keyframes ProgressAnimation {
  0% {
    background-position: 0% 10%;
  }
  50% {
    background-position: 100% 91%;
  }
  100% {
    background-position: 0% 10%;
  }
}

.actions-container {
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .actions-container {
    width: 100% !important;
  }
}

@media only screen and (min-width: 720px) {
  .filter-container {
    visibility: visible;
  }
  .filter-dialog {
    visibility: collapse;
    height: 0 !important;
    width: 0 !important;
  }
}

@media only screen and (max-width: 719px) {
  .filter-container {
    visibility: collapse;
    height: 0 !important;
    width: 0 !important;
  }
  .filter-dialog {
    visibility: visible;
  }
}